<template>
  <div class="contains" v-if="resObj">
    <!-- 当前等级 统计情况 -->
    <div class="lvl-line">
      <span class="lvl-no">{{hb.contentLevel}} .</span>
      <div class="lvl-items">
        <div class="lvl-item" v-for="(item,index) in currenWords" :key="index">
          <p class="character">{{item.word}}</p>
          <div class="line"></div>
          <span>{{item.count}}</span>
        </div>
      </div>
    </div>
    <!-- 选择标签 -->
    <div class="type-labels-wrapper">
      <div
        v-for="item in typeListNum"
        :key="item.key"
        class="type-label"
        :class="{'active':selectKey==item.key}"
        @click="selectKey=item.key"
      >
        <Badge :count="resObj[item.key]&&resObj[item.key].count">
          <span>{{item.label}}</span>
        </Badge>
      </div>
    </div>
    <!-- 包含等级 -->
    <div v-if="resObj[selectKey]" class="info-list">
      <div
        class="lvl-line"
        v-for="(lvlLine,index) in 'overDictWordList'===selectKey?[resObj[selectKey].list]:resObj[selectKey].list"
        :key="'w'+index"
      >
        <span class="lvl-no" v-if="lvlLine.length">
          {{lvlLine[0].level}}
          <span v-if="lvlLine[0].level">.</span>
        </span>
        <div class="lvl-items">
          <div class="lvl-item" v-for="(item,index) in lvlLine.filter(ele=>ele.count)" :key="index">
            <p class="character">{{item.word}}</p>
            <div class="line"></div>
            <span>{{item.count}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else></div>
</template>

<script >
import axios from "../../../api/newAxiosProxy";
import homerService from "../../../api/homePageService";
export default {
  props: {
    hb: {}
  },
  data() {
    return {
      typeListNum: [
        { label: "包含等级", key: "includeWordList" },
        { label: "超出等级", key: "overLevelWordList" },
        { label: "超出字库", key: "overDictWordList" }
      ],
      selectKey: "includeWordList",
      resObj: null,
      timmer: null,
      currenDefaultWords: [],
      currenWords: null
    };
  },
  methods: {
    initData() {
      let mdContentList = [],
        singleMdContent;
      // 韵文配音的生产用mdContentList,非韵文用singleMdContent
      if (/Verse/.test(this.hb.contentType)) {
        //配音阶段
        this.hb.backGroundList.forEach(picObj => {
          let str = picObj.divElementList.reduce(
            (lastValue, item) => lastValue + "\n\n" + item.divContent,
            ""
          );
          mdContentList.push(str.trim());
        });
        mdContentList.unshift(this.hb.title);
        singleMdContent = mdContentList.reduce(
          (lastV, item) => lastV + "\n\n" + item,
          ""
        );
      } else {
        singleMdContent = this.hb.mdContentList[this.currentIndex];
      }

      let contentType = this.hb.contentType;
      let wordType = /cn/.test(contentType) ? "cn" : "en";
      let contentLevel = this.hb.contentLevel;

      axios({
        method: "post",
        url: "/common/content/wordCount",
        data: {
          taskName: this.hb.taskName,
          singleMdContent,
          contentType,
          contentLevel,
          wordType,
          mdContentList
        }
      })
        .then(res => {
          this.resObj = res;
        })
        .catch(() => {
          this.resObj = {};
        })
        .finally(() => {
          if (
            this.resObj.includeWordList &&
            this.resObj.includeWordList.list &&
            this.resObj.includeWordList.list.length
          ) {
            this.currenWords = this.resObj.includeWordList.list[0];
          } else {
            this.currenWords = this.currenDefaultWords;
          }
        });
    },
    refresh() {
      if (this.timmer) {
        clearTimeout(this.timmer);
      }
      this.timmer = setTimeout(() => {
        this.initData();
        this.timmer = null;
      }, 500);
    }
  },
  beforeMount() {
    let laun = this.hb.contentType.substring(0, 2);
    let type = laun === "cn" ? 1 : 2;
    homerService
      .getWord({
        type,
        level: this.hb.contentLevel,
        pageNo: 1,
        pageSize: 1
      })
      .then(res => {
        this.currenDefaultWords = res.dataStore.rows[0].map(ele => {
          return { count: 0, word: ele.word };
        });
      });
  },
  watch: {
    hb: {
      handler() {
        if (this.hb) {
          this.refresh();
        }
      },
      immediate: true
    },
    currentIndex: {
      handler() {
        if (this.hb && !/Verse/.test(this.hb.contentType)) {
          this.refresh();
        }
      },
      immediate: true
    }
  }
};
</script>

<style scoped lang="less">
.contains {
  flex-direction: column;
  display: flex;
  align-items: center;
  .info-list {
    max-width: 85%;
  }
}
.type-labels-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #000;
  margin-top: 30px;
  .type-label {
    width: 80px;
    height: 30px;
    text-align: center;
    font-size: 13px;
    line-height: 30px;
    cursor: pointer;
  }
  .type-label.active {
    background: rgba(3, 132, 15, 1);
    color: #fff;
  }
}

.lvl-line {
  display: flex;
  margin-top: 30px;
  padding-right: 30px;
  .lvl-no {
    width: 3em;
    text-align: right;
    font-size: 26px;
    color: black;
    font-weight: bold;
  }
  .lvl-items {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
  }
}
.lvl-item {
  margin: 0 15px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 153, 51, 0.149019607843137);
  min-width: 1.25em;
  .character {
    font-size: 20px;
  }
  .line {
    background: #3ed67b;
    width: 100%;
    height: 2px;
    margin-bottom: 5px;
  }
}
</style>
